import { gql } from '@apollo/client';

export const UserFragment = gql`
  fragment UserFragment on User {
    _id
    firstName
    lastName
    userType
    email
    locale
    dni
    dniType
    active
    profilePicture
    socialId
    addresses {
      firstLine
      secondLine
      state
      city
      postalCode
    }
    phone
  }
`;

export const LogDetailsFragment = gql`
  fragment LogDetailsFragment on LogDetails {
    name
    value
    # _id
    # active
  }
`;

export const LogFragment = gql`
  fragment LogFragment on Log {
    _id
    title
    movementType
    status
    date
    arrivalDate
  }
`;

export const PromotionFragment = gql`
  fragment PromotionFragment on Promotion {
    _id
    name
    type
    modifier
    code
    active
  }
`;

export const CURRENCY_FRAGMENT = gql`
  fragment CurrencyFragment on Currency {
    _id
    name
    symbol
    rate
    active
  }
`;

export const AdvertisementFragment = gql`
  fragment AdvertisementFragment on Advertisement {
    _id
    name
    url
    active
  }
`;

export const PermissionFragment = gql`
  fragment PermissionFragment on Permission {
    _id
    name
    key
    options
    active
  }
`;

export const SessionFragment = gql`
  fragment SessionFragment on Session {
    _id
    user {
      ...UserFragment
    }
    token
    device
    active
  }
  ${UserFragment}
`;

export const PickupLocationFragment = gql`
  fragment PickupLocationFragment on PickupLocation {
    _id
    name
    phone
    email
    address {
      firstLine
      secondLine
      state
      city
      postalCode
      isFiscal
    }
    active
  }
`;

export const AddressFragment = gql`
  fragment AddressFragment on Address {
    firstLine
    secondLine
    state
    city
    postalCode
    isFiscal
  }
`;

export const ShipmentFragment = gql`
  fragment ShipmentFragment on Shipment {
    _id
    firstName
    lastName
    email
    phone
    addressUrl
    postalCode
    shippingType
    merchType
    boxesNumber
    width
    length
    height
    weight
    originCity
    trackingNumber
    boxesPictures
    packingList
    invoice
    shippingCost
    taxes
    productType
    deliveryInstructions
    active
    approved
    createdAt
    updatedAt
    cbm
    dangerous
    replica
  }
`;
export const PortFragment = gql`
  fragment PortFragment on Port {
    _id
    name
    country
    description
    active
  }
`;

export const VesselActionFragment = gql`
  fragment VesselActionFragment on VesselAction {
    _id
    port {
      ...PortFragment
    }
    arrivalDate
    departureDate
    history {
      ...LogFragment
    }
    active
  }
  ${LogFragment}
  ${PortFragment}
`;

export const VesselFragment = gql`
  fragment VesselFragment on Vessel {
    _id
    vesselCode
    lloydsNumber
    country
    buildIn
    callSign
    lineCode
    active
  }
`;

export const ContainerFragment = gql`
  fragment ContainerFragment on Container {
    _id
    name
    description
    company
    currentLocation
    status
    lastActivity
    history {
      ...LogFragment
    }
    vessel {
      ...VesselFragment
    }
    active
  }
  ${LogFragment}
  ${VesselFragment}
`;

export const OrderFragment = gql`
  fragment OrderFragment on Order {
    _id
    qrCode
    createdAt
    updatedAt
    code
    approved
    shippingType
    user {
      ...UserFragment
    }
    shipment {
      ...ShipmentFragment
    }
    status
    history {
      ...LogFragment
    }
    modality
    promotions {
      ...PromotionFragment
    }
    active
    finalWidth
    finalLength
    finalHeight
    finalWeight
    finalShippingCost
    finalCbm
    finalTaxes
    larkId
    receivedImages
  }
  ${UserFragment}
  ${ShipmentFragment}
  ${PromotionFragment}
  ${LogFragment}
`;

export const PaymentMethodDetailsFragment = gql`
  fragment PaymentMethodDetailsFragment on PaymentMethodDetails {
    name
    value
  }
`;

export const PaymentMethodFragment = gql`
  fragment PaymentMethodFragment on PaymentMethod {
    _id
    name
    logo
    currency {
      ...CurrencyFragment
    }
    details {
      ...PaymentMethodDetailsFragment
    }
    commission
    active
  }
  ${PaymentMethodDetailsFragment}
  ${CURRENCY_FRAGMENT}
`;

export const FreightFragment = gql`
  fragment FreightFragment on Freight {
    _id
    isHeavy
    heavyRate
    code
    paymentStatus
    larkId
    freightRate
    paidAmount
    active
    createdAt
    updatedAt
    user {
      ...UserFragment
    }
    shippingType
    arrivalDate
    departureDate
    status
    origin
    destination
    currentLocation
    history {
      ...LogFragment
    }
    orders {
      ...OrderFragment
    }
    cost
    dangerousRate
    replicaRate
    totalCbm
    totalDangerousCbm
    totalReplicaCbm
    dangerousCost
    replicaCost
    heavyCost
    inContainer
    isHeavy
    heavyRate
    hasPenalization
    penalization
    hasDiscount
    discount
    isForeign
    foreignRate
    customsAgency
    destinationTaxes
  }
  ${UserFragment}
  ${OrderFragment}
  ${LogFragment}
`;

export const PaymentFragment = gql`
  fragment PaymentFragment on Payment {
    _id
    larkId
    status
    createdAt
    updatedAt
    type
    image
    paymentDate
    freight {
      _id
      code
      larkId
    }
    reference
    amount
    method {
      ...PaymentMethodFragment
    }
  }
  ${PaymentMethodFragment}
`;
