import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import NProgress from 'nprogress';
import { Dialog, DialogContent, DialogTrigger } from '../ui/dialog';
import { Button } from '../ui/button';
import { CREATE_SUGGESTION } from '../../graphql/mutation';
import { useToast } from '../../hooks/useToast';
import { useUser } from '../../hooks';

interface SuggestionDialogProps {
  open: boolean;
  onClose: () => void;
}

const SuggestionDialog: React.FC<SuggestionDialogProps> = ({
  open,
  onClose,
}) => {
  const [user] = useUser();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [createSuggestion] = useMutation(CREATE_SUGGESTION);
  const { toast } = useToast();
  const [disabledButton, setDisabledButton] = useState(false);

  const handleSubmit = async () => {
    if (!title || !description) {
      toast({
        title: 'Faltan datos',
        description: 'Por favor, complete todos los campos',
        variant: 'warning',
      });
      return;
    }

    try {
      setDisabledButton(true);
      NProgress.start();

      const { data } = await createSuggestion({
        variables: {
          record: {
            title,
            description,
            user: user?._id,
          },
        },
      });

      toast({
        title: 'Creación exitosa',
        description: 'La sugerencia ha sido creada con éxito',
        variant: 'success',
      });
      // Reset form
      setTitle('');
      setDescription('');

      onClose();
    } catch (err) {
      toast({
        title: 'Error',
        description: 'Hubo un error al procesar su solicitud',
        variant: 'destructive',
      });
    } finally {
      NProgress.done();
      setDisabledButton(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent>
        <h2 className="text-lg font-medium">Sugerencias</h2>
        <div className="mt-4">
          <label
            htmlFor="title"
            className="block text-sm font-medium text-gray-700"
          >
            Título
          </label>
          <input
            type="text"
            id="title"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            disabled={disabledButton}
          />
        </div>
        <div className="mt-4">
          <label
            htmlFor="description"
            className="block text-sm font-medium text-gray-700"
          >
            Descripción
          </label>
          <textarea
            id="description"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            disabled={disabledButton}
          />
        </div>
        <div className="mt-4 flex justify-end">
          <Button
            variant="outline"
            onClick={onClose}
            className="mr-2"
            disabled={disabledButton}
          >
            Cancelar
          </Button>
          <Button onClick={handleSubmit} disabled={disabledButton}>
            Enviar
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SuggestionDialog;
