import * as React from 'react';
import Link from 'next/link';
import { animateScroll, Link as ScrollLink } from 'react-scroll';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { MainNavItem, SidebarNavItem } from '../models';
import { useLockBody } from '../hooks/useLockBody';
import { cn } from '../lib/utils';
import SuggestionDialog from './suggestions/SuggestionDialog';

interface MobileNavProps {
  items: SidebarNavItem[] | MainNavItem[];
  children?: React.ReactNode;
  setShowMobileMenu?: React.Dispatch<React.SetStateAction<boolean>>;
}

export function MobileNav({
  items,
  children,
  setShowMobileMenu,
}: MobileNavProps) {
  useLockBody();
  const router = useRouter();
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <div
      className={cn(
        'fixed inset-0 top-16 z-50 grid h-[calc(100vh-4rem)] grid-flow-row auto-rows-max overflow-auto pb-32 shadow-md animate-in slide-in-from-bottom-80 md:hidden'
      )}
    >
      <div className="relative z-20 grid gap-6 rounded-md bg-white p-4 shadow-md">
        <nav className="grid grid-flow-row auto-rows-max text-sm">
          {items?.map((item, index) =>
            item.title === 'Sugerencias' ? (
              <button
                type="button"
                key={index}
                onClick={() => setOpenDialog(true)}
                className={cn(
                  'flex w-full items-center rounded-md p-2 text-sm font-medium hover:underline',
                  item.disabled && 'cursor-not-allowed opacity-60'
                )}
              >
                {item.title}
              </button>
            ) : (
              <ScrollLink
                key={index}
                to={item?.key} // Use an empty string if the link is disabled
                smooth // Enable smooth scrolling
                duration={500} // Set the duration of the scroll animation to 500ms
                className={cn(
                  'flex w-full items-center rounded-md p-2 text-sm font-medium hover:underline',
                  item.disabled && 'cursor-not-allowed opacity-60'
                )}
                offset={-60}
                onClick={(e) => {
                  e.preventDefault();
                  router.push(item.href);
                  setShowMobileMenu(false);
                }}
              >
                {item.title}
              </ScrollLink>
            )
          )}
        </nav>
        {children}
      </div>
      <SuggestionDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      />
    </div>
  );
}
